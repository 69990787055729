import { LogoNetwork, LogoNews } from '@/assets/images/Logo';
import LocaleLink from '@/components/LocaleLink';
import { useSetMobileDevice } from '@/web-sdk/hooks/useMobileDevice';
import classnames from 'classnames';
import Image from 'next/image';
import { useMemo } from 'react';

interface RandomBannerProps {
    isMobile: boolean;
    isTablet: boolean;
    randomBannerIndex: number;
}

export const RandomBanner: React.FC<RandomBannerProps> = ({
    isMobile,
    isTablet,
    randomBannerIndex,
}: RandomBannerProps) => {
    const isMiniMobile = useSetMobileDevice(false, 450);

    const cnContaier = classnames('flex-grow w-full ', {
        'min-w-[380px] lg:min-w-[400px]': !isMobile,
        'min-w-[300px]': isMobile,
    });

    const bgHeight = isMobile ? 1080 : isTablet ? 1180 : 1119;

    const newsBanner = useMemo(() => {
        const cnContent = classnames('font-extrabold font-gilmer-bold text-primary-cta-layer-color-90', {
            'text-5xl mt-5 ': !isMiniMobile,
            'text-4xl': isMiniMobile,
        });

        const cnFollow = classnames('w-[150px]', {
            'mt-5': !isMobile,
            'mt-8': isMobile,
        });

        return (
            <LocaleLink href="https://x.com/Yellow" target="_blank" rel="noreferrer">
                <div className="relative rounded-md">
                    <div>
                        <Image
                            src="/images/banner-socials-bg.webp"
                            alt="yellow banner"
                            height={627}
                            width={627}
                            className="w-full h-auto rounded-md object-cover"
                            loading="eager"
                        />
                    </div>
                    <div className="absolute top-0 w-full p-8">
                        <div className="relative ml-1 mt-1.5 flex w-[200px] h-[50px] aspect-[3/1]">
                            <LogoNews width={120} height={40} />
                        </div>
                        <h3 className={cnContent}>Follow Us On</h3>
                        <h3 className={cnContent}>X.com</h3>
                        <div className="w-[90%] mt-6">
                            <Image
                                src="/images/banner-socials-dark.svg"
                                alt="yellow banner"
                                height={20}
                                width={300}
                                className="object-cover"
                                loading="eager"
                            />
                        </div>
                        <div className={cnFollow}>
                            <Image
                                src="/images/banner-socials-follow.svg"
                                alt="yellow banner"
                                height={98}
                                width={158}
                                className="object-cover"
                                loading="eager"
                            />
                        </div>
                    </div>
                </div>
            </LocaleLink>
        );
    }, [isMiniMobile, isMobile, bgHeight]);

    const networkBanner = useMemo(() => {
        return (
            <LocaleLink href="https://www.yellow.org/#solution" target="_blank" rel="noreferrer">
                <div className="relative rounded-md">
                    <div>
                        <Image
                            src="/images/banner-network-bg.webp"
                            alt="yellow banner"
                            height={627}
                            width={627}
                            className="w-full h-auto rounded-md object-cover"
                            loading="eager"
                        />
                    </div>
                    <div className="absolute top-0 w-full p-8 text-white">
                        <div className="relative ml-1 mt-1.5 flex w-[200px] h-[50px] aspect-[3/1]">
                            <LogoNetwork width={100} height={50} />
                        </div>
                        <h3 className="text-5xl font-extrabold font-gilmer-bold mt-3">Yellow</h3>
                        <h3 className="text-5xl font-extrabold font-gilmer-bold">Network</h3>
                        <h3 className="text-5xl font-gilmer-regular">Building</h3>
                        <h3 className="text-5xl font-extrabold font-gilmer-bold">Layer-3</h3>
                    </div>
                    <div className="absolute bottom-0 w-[85%] ml-[7%] mb-6">
                        <Image
                            src="/images/banner-socials-white.svg"
                            alt="yellow banner"
                            height={20}
                            width={300}
                            className="object-cover"
                            loading="eager"
                        />
                    </div>
                </div>
            </LocaleLink>
        );
    }, [bgHeight]);

    return <div className={cnContaier}>{randomBannerIndex === 0 ? newsBanner : networkBanner}</div>;
};
